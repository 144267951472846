import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/Layout"
import Seo from "@components/Seo"

const PageTemplate = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo yoastSeo={page?.seo} />

      <article className="">
        <header>
          <div className="bg-slate-100 dark:bg-slate-800">
            <div className="container--boxed-sm py-9 md:py-14">
              <h1 className="h1 dark:text-slate-200">{page.title}</h1>
            </div>
          </div>
        </header>

        <div className="container--boxed-sm pt-14 pb-0">
          <div
            className="prose dark:prose-invert"
            dangerouslySetInnerHTML={{ __html: page?.content }}
          ></div>
        </div>
      </article>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      ...WpPageFields
    }
  }
`
